<template>
  <div class="status-container " :class="cssClass">
    <span class="status-container--text text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    status: null,
  },

  computed: {
    text() {
      switch (this.status) {
        case 1:
          return this.$t('status.available');
        default:
          return this.$t('status.not_available');
      }
    },

    cssClass() {
      switch (this.status) {
        case 1:
          return 'green';
        default:
          return 'red'
      }
    },


  }
}
</script>

