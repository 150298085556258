<template>
  <div class="wameed-dashboard-page-content">

    <reviews
        :getTotalReviews="getTotal"

    />


    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"

          :searchPlaceholder="$t('common.search') + ' ...'"
          :btnTitle="$t('common.filter_data')"
          :menuTitle="$t('common.order_by') + ' : '"
          :subTitle="'asdf'"
          :orderByItems=" [
        {
          name: this.$i18n.t('common.newest'),
          id: 'desc',
        },
        {
          name: this.$i18n.t('common.older'),
          id: 'asc',
        }
      ]"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1 ">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :items="getData"
                :fields="tableFields"
                :custimized-items="[
                { name: 'rated_at' },
                { name: 'comment' },
                { name: 'vendor' },
                { name: 'rate' },
                { name: 'action' },
              ]"
            >

              <template slot="rate" slot-scope="{ data }">
                <customer-rate :rate="data.item.rate"/>
              </template>

              <template slot="vendor" slot-scope="{ data }">
                <check-value :value="data.item.vendor"/>
              </template>

              <template slot="comment" slot-scope="{ data }">
                <check-value :value="data.item.comment"/>
              </template>


              <template slot="rated_at" slot-scope="{ data }">
                {{ formatDate(data.item.rated_at, 'YYYY-MM-DD') }}
              </template>

              <template slot="action" slot-scope="{ data }">
                <b-dropdown

                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>


                  <b-dropdown-item
                      :to="goToDetail(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>


                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import reviews from './components/reviews.vue';
import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import GeneralStatusBadge from "@/components/generalStatusBadge";
import CheckValue from "@/components/checkValue";
import WarningModal from "@/components/WarningModal";
import FilterHeader from "@/components/wameed/WameedFilterHeader";
import CustomerRate from "@/components/customerRate";

export default {
  components: {
    reviews,

    GeneralStatusBadge,

    CustomerRate,
    CheckValue,
    WarningModal,
    PageHeader,
    FilterHeader,
    WameedPagination,
    WameedSwitch,
    WTables,

  },
  data() {
    return {
      modal: {
        title: this.$i18n.t('toggle.inactive.title'),
        subTitle: this.$i18n.t('toggle.inactive.sub_title'),
        btn: this.$i18n.t('toggle.inactive.btn'),
      },
      toggleData: {id: 0, status: 0},
      currentItemData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('filters.stars'),
          key: 'stars',
          id: 'id',
          name: 'title',
          selectedData: [],
          type: 'stars',
          data: [

            {
              id: 1,
              title: 1,
            },
            {
              id: 2,
              title: 2,
            },
            {
              id: 3,
              title: 3,
            },
            {
              id: 4,
              title: 4,
            },
            {
              id: 5,
              title: 5,
            },
          ]
        },
      ],

      tableFields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'no',
          label: this.$i18n.t('table.order.no'),
          sortable: false,
          tdClass: 'w-2/8'
        },

        {
          key: 'customer',
          label: this.$i18n.t('table.order.customer'),
          sortable: false,
          tdClass: 'w-2/8'
        },

        {
          key: 'rated_at',
          label: this.$i18n.t('table.rated_at'),
          sortable: false,
          tdClass: 'w-1/8'
        },


        {
          key: 'rate',
          label: this.$i18n.t('table.rate'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'comment',
          label: this.$i18n.t('table.comment'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/8'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/users/detail/getReviews',
      getTotal: 'admin/users/detail/getTotalReviews',
    }),
  },
  created() {
    this.loadData();
  },
  methods: {


    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page:1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {
      this.$store.dispatch('admin/users/detail/reviews', {
        ...this.filterData,
        type: this.$route.meta.type + 's',
        id: this.$route.params.id
      })
    },


    goToDetail(id) {
      return {
        name: 'reviews-detail',
        params: {lang: this.$i18n.locale, id},
      };
    },

    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>

