var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('reviews',{attrs:{"getTotalReviews":_vm.getTotal}}),_c('div',[_c('filter-header',{attrs:{"content":_vm.filterContent,"searchPlaceholder":_vm.$t('common.search') + ' ...',"btnTitle":_vm.$t('common.filter_data'),"menuTitle":_vm.$t('common.order_by') + ' : ',"subTitle":'asdf',"orderByItems":[
      {
        name: this.$i18n.t('common.newest'),
        id: 'desc',
      },
      {
        name: this.$i18n.t('common.older'),
        id: 'asc',
      }
    ]},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderData}})],1),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n      overflow-hidden\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 "},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table"},[(_vm.getData)?_c('w-tables',{attrs:{"noDataTitle":_vm.$t('table.no_data_title'),"noDataSubtitle":_vm.$t('table.no_data_subtitle'),"per-page":_vm.filterData.per_page,"items":_vm.getData,"fields":_vm.tableFields,"custimized-items":[
              { name: 'rated_at' },
              { name: 'comment' },
              { name: 'vendor' },
              { name: 'rate' },
              { name: 'action' } ]},scopedSlots:_vm._u([{key:"rate",fn:function(ref){
            var data = ref.data;
return [_c('customer-rate',{attrs:{"rate":data.item.rate}})]}},{key:"vendor",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.vendor}})]}},{key:"comment",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.comment}})]}},{key:"rated_at",fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.rated_at, 'YYYY-MM-DD'))+" ")]}},{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('b-dropdown',{staticClass:"table-option",attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-8","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon')]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":_vm.goToDetail(data.item.id)}},[_c('span',{staticClass:"text-regular-14 text-font-secondary"},[_c('eyeon-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.show_details'))+" ")],1)])],1)]}}],null,false,2249107872)}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotal)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotal.totalItems,"per_page":_vm.filterData.per_page,"menu-title":_vm.$t('common.show_results')},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }